/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		common: {
			init: function () {
				// JavaScript to be fired on all pages
				$("[data-toggle=tab]").click(function () {
					if ($(".navbar-collapse-tab").hasClass("in")) {
						$(".navbar-collapse-tab").toggleClass("in");
					}
					if ($(".sub-navbar-collapse-tab").hasClass("in")) {
						$(".sub-navbar-collapse-tab").toggleClass("in");
					}
				});

				$(window).on("load resize scroll touchmove", function () {
					var winWidth = $(window).width();
					var topHeight = 225 - $(document).scrollTop();
					if (topHeight <= 88 && winWidth >= 992) {
						$(".brand").css("height", "88px");
					} else {
						$(".brand").css("height", topHeight + "px");
					}
				});

				$.ajaxSetup({ cache: false });

				$(".menu-link").click(function () {
					var post_link = $(this).attr("data-url");
					$("#ajaxmodal").attr("data-permalink", post_link);
				});

				var $this = $("#ajaxmodal");

				$this.on("shown.bs.modal", function () {
					var permalink = $(this).attr("data-permalink") + " #thumbnail";
					$this
						.find(".ajaxcontent")
						.html(
							'<div class="ajax-loader"><svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="64px" height="64px" viewBox="0 0 128 128" xml:space="preserve"><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#000"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="800ms" repeatCount="indefinite"></animateTransform></g></svg></div>'
						);
					$this.find(".ajaxcontent").load(permalink);
					return false;
				});
				$this.on("hide.bs.modal", function (e) {
					$this.attr("data-permalink", "");
					$this.find(".ajaxcontent").html("");
					history.pushState("", document.title, window.location.pathname);
				});
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			},
		},
		// Home page
		home: {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			},
		},
		// About us page, note the change from about-us to about_us.
		about_us: {
			init: function () {
				// JavaScript to be fired on the about us page
			},
		},
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = funcname === undefined ? "init" : funcname;
			fire = func !== "";
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === "function";

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire("common");

			// Fire page-specific init JS, and then finalize JS
			$.each(
				document.body.className.replace(/-/g, "_").split(/\s+/),
				function (i, classnm) {
					UTIL.fire(classnm);
					UTIL.fire(classnm, "finalize");
				}
			);

			// Fire common finalize JS
			UTIL.fire("common", "finalize");
		},
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
